export function dateForm(e) {
  let d = new Date(e);
  let dHours = "";
  let dSeconds = "";
  let dMinutes = "";
  let dMonths = "";
  let dDate = "";
  if (d.getHours() < 10) {
    dHours = "0" + d.getHours();
  } else {
    dHours = d.getHours();
  }
  if (d.getSeconds() < 10) {
    dSeconds = "0" + d.getSeconds();
  } else {
    dSeconds = d.getSeconds();
  }
  if (d.getMinutes() < 10) {
    dMinutes = "0" + d.getMinutes();
  } else {
    dMinutes = d.getMinutes();
  }

  if (d.getMonth() + 1 < 10) {
    dMonths = "0" + (d.getMonth() + 1);
  } else {
    dMonths = d.getMonth() + 1;
  }
  if (d.getDate() < 10) {
    dDate = "0" + d.getDate();
  } else {
    dDate = d.getDate();
  }

  d =
    d.getFullYear() +
    "-" +
    dMonths +
    "-" +
    dDate +
    " " +
    dHours +
    ":" +
    dMinutes +
    ":" +
    dSeconds;
  return d;
}
