<template>
  <div class="container">
    <div class="main">
      <div class="header_box flexSb">
        <div class="flexSt list_box">
          <div class="flexSt list list2">
            <div class="title">年级名称</div>
            <el-select
              @change="change_level"
              v-model="form.grade"
              placeholder="请选择或搜索年级名称"
              filterable>
              <el-option
                :label="item.text"
                :value="item.value"
                v-for="item in level_list"
                :key="item.value"></el-option>
            </el-select>
          </div>
          <div class="flexSt list">
            <div class="title">班级名称</div>
            <el-select
              clearable
              @change="handleSearch"
              v-model="form.class_id"
              placeholder="请选择或搜索班级名称"
              filterable>
              <el-option
                :label="item.class_name"
                :value="item.id"
                v-for="item in class_list"
                :key="item.id"></el-option>
            </el-select>
          </div>
          <div class="flexSt list">
            <div class="title">课堂类型</div>
            <el-select
              @change="handleSearch"
              v-model="form.course_type"
              placeholder="请选择或搜索课堂类型"
              filterable>
              <el-option
                v-for="(item, index) in classTypelist"
                :key="item.id"
                :label="item.name"
                :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="flexSt list">
            <div class="title">上课时间</div>
            <el-date-picker
              @change="handleChangeTime"
              v-model="value1"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </div>
          <div class="flexSt list">
            <div class="title">课堂状态</div>
            <el-select
              @change="handleSearch"
              v-model="form.course_status"
              placeholder="请选择课堂状态"
              filterable>
              <el-option
                v-for="(item, index) in courseStatus"
                :key="item.id"
                :label="item.name"
                :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="flexSt list">
            <div class="title">备课状态</div>
            <el-select
              @change="handleSearch"
              v-model="form.is_prepared"
              placeholder="请选择备课状态"
              filterable>
              <el-option
                v-for="(item, index) in preparedlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="flexSt list">
            <el-button
              @click="handleSearch"
              type="primary"
              icon="el-icon-search"
              >搜索</el-button
            >
            <el-button @click="handleclearSearch" icon="el-icon-refresh"
              >重置</el-button
            >
          </div>
        </div>
      </div>

      <div class="table_box">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="tableHeaderColor"
          :header-cell-class-name="cellClass"
          :cell-class-name="returnName">
          <el-table-column type="selection" width="25"> </el-table-column>
          <el-table-column
            :index="indexMethod"
            type="index"
            label="序号"
            width="60"
            align="center">
          </el-table-column>

          <el-table-column
            align="center"
            width="260"
            label="上课时间"
            sortable
            prop="date">
            <template slot-scope="scope"
              >{{ scope.row.date }}{{ scope.row.time }}
            </template>
          </el-table-column>
          <el-table-column
            width="160"
            label="班级名称"
            sortable
            align="center"
            prop="class_name">
            <template slot-scope="scope">
              <div style="padding-right: 16px">{{ scope.row.class_name }}</div>
            </template>
          </el-table-column>
          <el-table-column
            width="160"
            label="所属年级"
            sortable
            align="center"
            prop="grade_name">
            <template slot-scope="scope">
              <div style="padding-right: 16px">{{ scope.row.grade_name }}</div>
            </template>
          </el-table-column>
          <el-table-column label="类型" min-width="90" align="center">
            <template slot-scope="scope">
              {{ scope.row.course_type == 1 ? "常规体育课" : "大课间" }}
            </template>
          </el-table-column>
          <el-table-column label="课堂风采" align="center" min-width="120">
            <template slot-scope="scope">
              {{ scope.row.elegant_moment }}张
            </template>
          </el-table-column>
          <el-table-column
            label="到课数/总人数"
            width="140"
            align="center"
            prop="attendance_count">
            <template slot-scope="scope">
              {{ scope.row.attendance_count }}/{{ scope.row.student_count }}
            </template> </el-table-column
          ><el-table-column
            label="备课状态"
            min-width="90"
            align="center"
            prop="big_break_rate">
            <template slot-scope="scope">
              <el-tag
                type="danger"
                v-if="scope.row.is_prepared == 0 && scope.row.course_type != 2"
                >未备课</el-tag
              >
              <el-tag
                type="success"
                v-if="scope.row.is_prepared == 1 && scope.row.course_type != 2"
                >已备课</el-tag
              >
              <el-tag type="info" v-if="scope.row.course_type == 2"
                >无需备课</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="课堂状态" min-width="90" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.course_status == 0">待上课</el-tag>
              <el-tag type="success" v-if="scope.row.course_status == 1"
                >上课中</el-tag
              ><el-tag type="info" v-if="scope.row.course_status == 2"
                >已下课</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            fixed="right"
            label="操作"
            align="center">
            <template slot-scope="scope">
              <div class="flexSc">
                <el-button type="text" @click="handleToDetail(scope.row)"
                  >查看</el-button
                >
                <el-button
                  style="margin-left: 10px"
                  v-if="scope.row.course_type == 1"
                  type="text"
                  @click="handleToclassreport(scope.row)"
                  >课堂报告</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>

        <div class="flexSe elpla">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getUser } from "@/utils/auth";
import { dateForm } from "@/utils/dataForm";
import { courseLog, getGradelist, getClasslist } from "@/api/classroon/record";
export default {
  data() {
    return {
      teacher_id: "",
      form: {
        grade: "",
        class_id: "",
        course_type: -1,
        course_status: -1,
        is_prepared: -1,
      },
      level_list: [],
      class_list: [],
      classTypelist: [
        // -1 = 全部，1常规体育课 2大课间
        {
          name: "全部",
          id: -1,
        },
        {
          name: "常规体育课",
          id: 1,
        },
        {
          name: "大课间",
          id: 2,
        },
      ],
      courseStatus: [
        // -1全部 0未开始 1进行中 2已结束
        {
          name: "全部",
          id: -1,
        },
        {
          name: "待上课",
          id: 0,
        },
        {
          name: "上课中",
          id: 1,
        },
        {
          name: "已下课",
          id: 2,
        },
      ],
      preparedlist: [
        // 0未备课 1已备课
        {
          name: "全部",
          id: -1,
        },
        {
          name: "未备课",
          id: 0,
        },
        {
          name: "已备课",
          id: 1,
        },
      ],
      value1: [],
      tableData: [1],
      pageInfo: {
        pageSize: 10,
        page: 1,
        total: 0,
      },
    };
  },
  computed: {
    indexMethod() {
      // 当前页数 - 1 * 每页数据条数 + 1
      return (this.pageInfo.page - 1) * this.pageInfo.pageSize + 1;
    },
  },
  mounted() {
    const date1 = new Date();
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date1.setHours(23, 59, 59, 999);
    // console.log(dateForm(date), dateForm(date1));

    this.form.start_time = dateForm(date);
    this.form.end_time = dateForm(date1);
    this.value1 = [this.form.start_time, this.form.end_time];
    this.teacher_id = getUser().id;
    this.get_level();
    this.handleGetcourseLog();
  },
  methods: {
    handleChangeTime(val) {
      if (val) {
        this.form.class_start_time = val[0];
        this.form.class_end_time = val[1];
        this.form.start_time = val[0];
        this.form.end_time = val[1];
      } else {
        this.form.class_start_time = "";
        this.form.class_end_time = "";
        this.form.start_time = "";
        this.form.end_time = "";
      }
      this.handleSearch();
    },
    change_level(val) {
      this.get_class();
      this.handleSearch();
    },
    async get_level() {
      let data = {
        teacher_id: this.teacher_id,
      };
      await getGradelist(data).then((res) => {
        this.level_list = res.data;
        this.level_list.unshift({
          value: "",
          text: "全部",
        });
        this.form.grade = this.level_list[0].value;
        this.get_class();
      });
    },
    async get_class() {
      let data = {
        id: this.form.grade,
        teacher_id: this.teacher_id,
      };
      await getClasslist(data).then((res) => {
        this.class_list = res.data;
        this.class_list.unshift({
          id: "",
          class_name: "全部",
        });
        this.form.class_id = this.class_list[0].id;
      });
    },
    async handleGetcourseLog() {
      let data = {
        ...this.form,
        per_page: this.pageInfo.pageSize,
        page: this.pageInfo.page,
      };
      await courseLog(data).then((res) => {
        this.tableData = res.data.data;
        this.pageInfo.total = res.data.total;
      });
    },
    handleToDetail(val) {
      if (val.course_type == 1) {
        this.$router.push("/preparation?val=" + JSON.stringify(val));
      } else {
        this.$router.push("/classstyle?val=" + JSON.stringify(val));
      }
    },
    handleToclassreport(val) {
      this.$router.push("/classreport?val=" + JSON.stringify(val));
    },
    handleclearSearch() {
      this.form = {
        grade: "",
        class_id: "",
        course_type: -1,
        course_status: -1,
        is_prepared: -1,
      };
      this.handleSearch();
    },
    handleSizeChange(val) {
      this.pageInfo.page = 1;
      this.pageInfo.pageSize = val;
      this.handleGetcourseLog();
    },
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.handleGetcourseLog();
    },
    handleSearch() {
      this.pageInfo.page = 1;
      this.handleGetcourseLog();
    },
    handleSelectionChange() {},
    cellClass() {
      return "disableSelection";
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: "16px",
        color: "#333333",
        paddingTop: "8px",
        paddingBottom: "8px",
        backgroundColor: "#F7F7F7",
      };
    },
    returnName(obj) {
      if (obj.columnIndex == 0) {
        return "firstCell";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100%;
}
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  .table_box {
    /deep/.disableSelection {
      // background-color: pink;
      /deep/.cell {
        // background-color: red;
      }
    }
  }
  .elpla {
    margin-top: 30px;
  }
  .header_box {
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    padding-bottom: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    .list_box {
      flex-wrap: wrap;
    }
    .list {
      margin-bottom: 15px;
      //   width: 280px;
    }
    .list2 {
      /deep/.el-cascader {
        width: 160px !important;
        margin-right: 10px;
      }
    }
    /deep/.title {
      color: rgb(78, 89, 105);
      font-size: 14px;
      margin-right: 8px;
      // min-width: 60px;
    }

    /deep/.el-button {
      padding: 0 20px;
      // height: 30px !important;
      // line-height: 30px;
      height: 40px !important;
      line-height: 40px;
    }

    /deep/.el-input {
      margin-right: 15px;
      width: 200px;
    }
    /deep/.el-input__inner,
    /deep/.el-input {
    }

    /deep/.el-input__icon {
      line-height: 32px !important;
    }
    .el-date-editor {
      margin-right: 15px;
    }
  }
  ::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
    // background-color: #ccc !important;
  }
  ::-webkit-scrollbar-track {
    // background-color: #bbbbbb;
  }
  /* 滚动条上的滚动滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
}
</style>
